import { render, staticRenderFns } from "./nav.vue?vue&type=template&id=80a88d66"
import script from "./nav.vue?vue&type=script&lang=js"
export * from "./nav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLogoLarge: require('/usr/src/eventum-portal/components/icon/logo-large.vue').default,IconClose: require('/usr/src/eventum-portal/components/icon/IconClose.vue').default,IconRight: require('/usr/src/eventum-portal/components/icon/IconRight.vue').default,SiteAuth: require('/usr/src/eventum-portal/components/site/auth.vue').default})
